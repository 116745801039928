.reports {
  margin: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  &_header {
    margin: auto;
  }
  &_tp {
    &__header {
    }
  }
}
