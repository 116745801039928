.price-ladder {
  &__controls {
    @include headerSelect;

    & .type__dropdown {
      & .type__header {
        padding: 0.2rem;
        border: 1px solid var(--color-primary);
        border-radius: 3px;
        color: var(--color-primary);
      }
      & .type__items {
        list-style: none;
        position: absolute;
        width: fit-content;
        border: 1px solid var(--color-primary);
        background-color: #f8f8f8;
        @include itemCenter(column);
      }
      & .type__item {
        padding: 0.5rem 1rem;
        @include itemCenter(row);
        &:hover {
          background-color: #e0e3e8;
        }
      }
    }
  }
  & h5 {
    margin: 2rem 0;
  }
}
.right {
  color: red;
}
