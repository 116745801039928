.supporting-documents {
  &__select {
    @include headerSelect;
  }
  &__heading {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
    & .btn-download {
      @include btnPlain;
      color: var(--color-faded-darker);
      &:active {
        animation: onClickBtn 0.05s ease-out;
      }
      &:hover {
        background-color: rgb(242, 242, 242);
      }
    }
  }
}
