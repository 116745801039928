.main-toggle {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  & .toggle {
    background-color: #0091cf;
    width: 1.7rem;
    height: 0.9rem;
    display: flex;
    align-items: center;
    border-radius: 10px;
    &__toggler {
      height: 0.8rem;
      width: 0.8rem;
      border-radius: 50%;
      background-color: #fff;
    }
    &.left {
      justify-content: flex-end;
    }
  }
  & p {
    color: var(--color-faded-dark);
  }
}
.your-choice {
  &__controls {
    @include headerSelect;
    & .ant-btn-default {
      border-color: var(--color-primary);
      color: var(--color-primary);
    }
    & .ant-btn-primary {
      @include itemCenter(row);
      background-image: linear-gradient(90deg, #0097ce, #014eb3);
      color: #fff;
      & svg {
        font-size: 1.3rem;
      }
    }
    & .btn-control-delete {
      background-color: #7f848e;
      color: #fff;
    }
    & .type__dropdown {
      & .type__header {
        padding: 0.2rem;
        border: 1px solid var(--color-primary);
        border-radius: 3px;
        color: var(--color-primary);
      }
      & .type__items {
        list-style: none;
        position: absolute;
        width: fit-content;
        border: 1px solid var(--color-primary);
        background-color: #f8f8f8;
        @include itemCenter(column);
      }
      & .type__item {
        padding: 0.5rem 1rem;
        @include itemCenter(row);
        &:hover {
          background-color: #e0e3e8;
        }
      }
    }
  }
  & .details-controls {
    display: flex;
    justify-content: flex-end;
    padding: 5px 1rem;
    margin: 1.3rem 0;
    gap: 0.8rem;
    & > button,
    .btn-details {
      @include btnPlain;
      color: var(--color-primary);
      &:active {
        animation: onClickBtn 0.05s ease-out;
      }
      &:hover {
        background-color: rgb(242, 242, 242);
      }
    }
  }
}
.data-options {
  margin-top: 1rem;
  margin-right: 1rem;
  display: flex;
  justify-content: space-between;
  &__tools {
    @include itemCenter(row);
    gap: 0.5rem;
    & span {
      color: var(--color-faded-dark);
    }
  }
}

.single-row {
  display: flex;
  justify-content: space-between;
  gap: 3rem;
  border: 1px solid #b4b4b43d;
  padding-right: 1rem;
  color: var(--color-faded-dark);

  &__heading {
    text-transform: uppercase;
    flex: 0 0 13rem;
    background-color: #edf4fa;
    padding: 5px 1rem;
  }
  &__item {
    width: 200px;
  }
}
.main-row {
  font-size: 0.8rem;
  display: flex;
  justify-content: space-between;
  border: 1px solid #b4b4b43d;
  padding: 5px 1rem 5px 0px;
  &__heading {
    flex: 0 0 13rem;
    padding: 5px 1rem;
  }
  &__item {
    text-align: center;
    width: 200px;
  }
}
.btn-details {
  @include btnPlain;
  color: var(--color-primary);
  &:active {
    animation: onClickBtn 0.05s ease-out;
  }
  &:hover {
    background-color: rgb(242, 242, 242);
  }
}
