.admin-login {
  width: 100%;
  height: 100vh;
  display: flex;
  &__form-area {
    height: 100%;
    padding: 2rem;
    flex: 0 0 25rem;
    background-color: rgba(227, 226, 226, 0.27);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    & > form {
      width: 100%;
    }
  }
  &__logo {
    background-color: rgb(44, 44, 44);
    & > img {
      width: 100%;
    }
  }
}
