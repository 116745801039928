@mixin itemCenter($direction) {
  display: flex;
  flex-direction: $direction;
  align-items: center;
  justify-content: center;
}

@mixin btn {
  background-color: #019ffc;
  outline: none;
  border: none;
  padding: 1rem 1.5rem;
  color: #fff;
}
@mixin btnPlain {
  background: none;
  outline: none;
  border: none;
  padding: 2px 0.8rem;
  box-shadow: -1px 2px 3px rgb(137 137 137 / 60%);
  border-radius: 3px;
}
@mixin headerSelect {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
}
