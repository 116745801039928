:root {
  --color-faded-dark: #0000009a;
  --color-faded-darker: #000000b7;
  --color-faded-light: #0000003d;
  --color-primary: #0097ce;

  --line: 1px solid var(var(--color-faded-dark));
}
body {
  margin: 0;
  padding: 0;
}
*,
*::after,
*::before {
  box-sizing: inherit;
  list-style: none;
}
ol,
ul {
  padding-left: 0;
}
html {
  box-sizing: border-box;
}
a {
  color: #fff;
  text-decoration: none;
}
p {
  margin-top: 3px;
}
.vat {
  color: red;
  text-transform: capitalize;
}
.main-header {
  display: flex;
  align-items: center;
  gap: 5rem;
  justify-content: space-between;
}
