.input-feilds {
  width: 60%;
  border-top: none;
  border-left: none;
  border-right: none;
  border-color: grey;
  border-bottom-width: 0.5px;
}

.remember-me-inpt {
  color: grey;
  font-size: 15px;
  display: flex;
  align-items: baseline;
  padding-left: 24%;
}

.fill {
  height: 100vh;
  background-color: #2d323f;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-logo {
  width: 70%;
}

.fill-main {
  background-color: #fff;
  margin: auto;
  height: fit-content;
  width: 70%;
  padding: 10px;
  border-radius: 20px;
  max-width: 1200px;
}

.login-btn:hover {
  background-image: linear-gradient(
    109.6deg,
    #17a2b8 11.2%,
    rgb(19 40 242) 91.1%
  );
  border: none;
  width: 30%;
}

.login-btn {
  background-color: white;
  color: rgb(39, 39, 39);
  border-color: grey;
  width: 30%;
  font-weight: 600;
  min-width: 137px;
}

#login-btn {
  width: 20%;
}

#login-btn:hover {
  width: 20%;
}

.login-buttn {
  display: flex;
  justify-content: space-evenly;
  padding-left: 5%;
}

.forget-pass {
  color: grey;
}

.forget-pass:hover {
  cursor: pointer;
}

.right-data {
  background-image: linear-gradient(
    109.6deg,
    rgb(19 40 242) 11.2%,
    #17a2b8 91.1%
  );
  width: 100%;
  height: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
}

.right-data-header {
  color: white;
}

.login-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  align-items: center;
}

.login-form {
  width: 100%;
}
