.navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  &__user {
    gap: 0.625rem;
    padding: 1rem 1rem;
    box-shadow: 0px 1px 3px #8989899a;
    & .sidebar__toggle {
      @include itemCenter(row);
      font-size: 2rem;
      color: var(--color-faded-dark);
    }
  }
  &__admin {
    padding: 1rem;
    background: none;
    border: none;
    & .sidebar__toggle {
      border-radius: 7px;
      font-size: 1.4rem;
      @include itemCenter(row);
      padding: 5px 12px;
      background-color: #e3e7ef;
    }
    &--control {
      display: flex;
      gap: 5px;
      position: relative;
      cursor: pointer;
      user-select: none;
      &__popup {
        width: 15rem;
        position: absolute;
        background-color: #e6e6e6;
        top: 3.5rem;
        border-radius: 5px;
        right: 0;
        display: none;
        & > li {
          padding: 5px 10px;
          width: 100%;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          user-select: none;
          & svg {
            color: var(--color-faded-darker);
          }
          &:hover {
            background-color: #dadada;
          }
        }
        &.show {
          display: block;
        }
      }

      & p {
        vertical-align: middle;
        color: var(--color-faded-dark);
        font-size: 0.9rem;
      }
      & svg {
        font-size: 1.3rem;
        transform: rotate(90deg);
        color: #019ffc;
        align-self: center;
      }
    }
  }
  &__search {
    margin-right: auto;
    @include itemCenter(row);
    width: 80%;
    max-width: 360px;
    position: relative;
    & input {
      width: 100%;
      padding: 5px 5px 5px 2rem;
      border: 1px solid var(--color-faded-dark);
      outline: none;
      border-radius: 1rem;
      border: none;
    }
    & svg {
      position: absolute;
      left: 0.625rem;
      color: var(--color-faded-dark);
    }
  }
  &__user-section {
    display: flex;
    align-items: stretch;
    gap: 5px;
    height: 2rem;
    margin-left: auto;
    padding: 0 0.625rem;
    & svg {
      color: #39a1c0;
      font-size: 1.2rem;
      align-self: center;
    }
    & p {
      color: var(--color-faded-dark);
      margin-top: 3px;
    }
  }
  &__flag {
    display: flex;
    align-items: stretch;
    gap: 5px;
    border-left: 2px solid var(--color-faded-dark);
    height: 2rem;
    align-self: center;
    padding: 0 0.625rem;
    & p {
      color: var(--color-faded-dark);
      margin-top: 3px;
    }
  }
  &__filter {
    @include itemCenter(row);
    font-size: 1.6rem;
    & svg {
      color: var(--color-faded-dark);
    }
  }
}
