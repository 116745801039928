.import-data {
  &__header {
    & > h5 {
      color: var(--color-faded-darker);
    }
  }
  &__dropdowns {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-start;
    gap: 2rem;
  }
}
