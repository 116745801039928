.vendors {
  width: 100%;
  &__header {
    background-color: #f8f8f8;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & p {
      display: flex;
      align-self: flex-start;
      font-size: 1.3rem;
      color: var(--color-faded-dark);
    }
  }

  &__body {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  &__table-controls {
    display: flex;
    justify-content: space-between;
  }
}
.row-count {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  z-index: 1;
  &__dropdown {
    padding: 0 3px;
    height: 2rem;
    width: 6rem;
    border: 1px solid var(--color-faded-light);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }
  &__items {
    list-style: none;
    position: absolute;
    width: 6rem;
    border: 1px solid var(--color-faded-light);
    top: 2rem;
    left: -1px;
    background-color: #f8f8f8;
    display: none;
    &.visible {
      display: block;
    }
  }
  &__item {
    padding: 0.5rem 1rem;
    @include itemCenter(row);
    &:hover {
      background-color: #e0e3e8;
    }
  }
}

.search {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  & input {
    padding: 0.4rem;
    outline: none;
    border: 1px solid var(--color-faded-light);
  }
  &p {
    font-size: 0.7rem;
  }
}
