.main-toggle {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  & .toggle {
    background-color: #0091cf;
    width: 1.7rem;
    height: 0.9rem;
    display: flex;
    align-items: center;
    border-radius: 10px;
    &__toggler {
      height: 0.8rem;
      width: 0.8rem;
      border-radius: 50%;
      background-color: #fff;
    }
    &.left {
      justify-content: flex-end;
    }
  }
  & p {
    color: var(--color-faded-dark);
  }
}
.pricing-summary {
  &__controls {
    @include headerSelect;
    & .type__items {
      list-style: none;
      position: absolute;
      width: fit-content;
      border: 1px solid var(--color-primary);
      background-color: #f8f8f8;
      @include itemCenter(column);
    }
  }
}
.data-options {
  margin-top: 1.8rem;
  margin-right: 1rem;
  display: flex;
  justify-content: space-between;

  &__tools {
    @include itemCenter(row);
    gap: 1rem;
    & span {
      cursor: pointer;
      font-size: 1.3rem;
      color: var(--color-faded-darker);
    }
  }
}
.breakdownOffer {
  max-height: 450px;
  width: 300px;
  overflow-y: scroll;
  padding: 2rem 1rem 1rem 1rem;
  color: var(--color-faded-darker);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  // font-size: 1.05rem;
  max-width: 30rem;
  &:not(:last-child) {
    border-bottom: 1px solid var(--color-faded-dark);
  }
  &_option {
    border-bottom: 1px solid var(--color-faded-dark);
    margin-bottom: 1rem;
    word-wrap: normal;
    font-weight: 600;
    text-align: center;
  }
  &_value {
    & > span {
      font-weight: 600;
    }
  }
}

.breakdownOffer_close {
  position: absolute;
  right: 1rem;
  cursor: pointer;
}
