.ant-table-column-title {
  z-index: 0;
}
p {
  margin-top: revert;
}
a:hover {
  color: unset;
}
// td > .ant-space {
//   & .ant-space-item {
//     border: 1px solid var(--color-faded-light);
//     padding: 2px;
//     & a {
//       color: #000;
//     }
//   }
// }

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
}
.ant-select-arrow {
  & .anticon {
    & svg {
      color: var(--color-primary);
    }
  }
}
//for user Table
.user-table__data {
  & .ant-select {
    & .ant-select-selector {
      border: 1px solid var(--color-faded-light);
      color: #212529;
    }
    & .ant-select-arrow {
      & .anticon {
        & svg {
          color: #212529;
        }
      }
    }
  }
}
:global {
  .progress {
    .ant-progress-circle-trail {
      stroke: #175372 !important;
    }
    .ant-progress-text {
      color: #e4e8ec;
      font-size: 14px;
      font-weight: bold;
    }
  }
}
.ant-table-footer {
  color: var(--color-primary);
}
.ant-select-selection-placeholder {
  color: var(--color-faded-darker);
}
// [title="Email"],
// [title="Password"] {
//   color: rgb(255, 255, 255) !important;
// }
