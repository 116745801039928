@import "https://code.highcharts.com/css/highcharts.css";
.highcharts-legend-item {
  & .highcharts-graph {
    position: absolute;
    display: none;
  }
}

.highlight .highcharts-data-label-box {
  fill: red;
  stroke-width: 2px;
  stroke: black;
}

#container {
  height: 400px;
  min-width: 300px;
  max-width: 800px;
  margin: 0 auto;
}

.highcharts-data-label-box {
  fill: #a4edba;
  stroke: gray;
  stroke-width: 1px;
}

.highcharts-data-label {
  font-weight: normal;
}

.highlight .highcharts-data-label-box {
  fill: red;
  stroke-width: 2px;
  stroke: black;
}

.highlight.highcharts-data-label text {
  font-weight: bold;
  fill: white;
}
