.price-trend {
  & .graph-heading {
    padding: 1rem;
  }
  &__select {
    @include headerSelect;
  }
  &__graph {
    padding: 2rem;
    box-shadow: 0px 1px 3px #8989899a;
    border-radius: 5px;
  }
  & .price-trend-toggle {
    padding: 0.8rem;
    display: flex;
    justify-content: flex-end;
  }
}
.net-transaction,
.dealer-price,
.launch-month {
  margin-top: 1rem;
  box-shadow: 0px 1px 3px #8989899a;
  &__header {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    & > h6 {
      color: var(--color-primary);
    }
  }
}
.graph-price-trend {
  display: flex;
  justify-content: space-between;
}
.btn_pptx {
  background: none;
  border: none;
  outline: none;
  font-size: 1.6rem;
  color: var(--color-faded-dark);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
