.country-model {
  width: 30rem;
  height: fit-content;
  padding: 1rem;
  border: 1px solid var(--color-faded-light);
  background-color: #fff;
  margin: auto;
  position: absolute;
  top: -1rem;
  right: 3rem;
  left: 50%;

  &__header {
    & h3 {
      text-align: center;
    }
  }
  &__form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    & input {
      width: 100%;
      font-size: 1.05rem;
      padding: 0.7rem;
      border-radius: 0.5rem;
      outline: none;
      border: 1px solid var(--color-faded-light);
    }
  }
}
