table {
  width: 100%;
  min-width: 1000px;
  text-align: center;
  font-size: 0.9rem;
  border: none;
}
.user-table {
  overflow-x: scroll;
  scrollbar-width: none;
  box-shadow: 0px 1px 3px #8989899a;
  &::-webkit-scrollbar {
    display: none;
  }
  & th {
    padding: 10px;
    color: var(--color-faded-dark);
    background-color: #edf4fa;
  }
  & .sd-table {
    border-bottom: 1px solid var(--color-faded-light);
    cursor: pointer;
    & > td {
      color: var(--color-primary);
    }
  }
  & td {
    color: var(--color-faded-dark);
    font-size: 0.8rem;
    font-weight: 500;
    padding: 7px 10px;
  }
}
