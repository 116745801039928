.dashboard-home {
  width: 100%;
  &__table {
    box-shadow: 0px 1px 3px #8989899a;
    & > h5 {
      padding: 1rem 2rem;
    }
  }
  & > h5 {
    padding: 1rem;
  }
  &__header {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
  }
  &__header-card {
    padding: 1rem;
    border-radius: 5px;
    @include itemCenter(row);
    gap: 1rem;
    box-shadow: 0px 1px 3px #8989899a;
  }
  &__content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    & .content__main {
      display: flex;
      flex-direction: column;
      gap: 3px;
      border-bottom: 1px solid var(--color-faded-light);
      & .count {
        font-size: 1.1rem;
        color: var(--color-primary);
      }
      & .heading {
        color: var(--color-faded-darker);
      }
    }
    & .content__footer {
      & > span {
        color: var(--color-faded-dark);
        font-size: 0.8rem;
      }
    }
  }
  &__charts {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 2rem 0;
  }
}
.bar-chart {
  padding: 1rem;
  flex: 0 0 65%;
  box-shadow: 0px 1px 3px #8989899a;
}
.pie-chart {
  max-width: 50rem;
  box-shadow: 0px 1px 3px #8989899a;
  flex-grow: 1;
  padding: 1rem;
  & .pie-progress {
    padding: 2rem;
    @include itemCenter(row);
  }
  &__description {
    display: flex;
    justify-content: space-around;
  }
  &__details {
    display: flex;
    flex-direction: column;
    align-items: center;
    & .details-item {
      display: flex;
      gap: 5px;
      align-items: center;
      & .details-color {
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
      }
    }
  }
}
.dashboard-header-progress-text {
  & > div {
    & > span {
      font-weight: 500;
    }
  }
}
