.ant-tooltip {
  @media screen and (min-width: 889px) {
    display: none;
  }
}
.sidebar {
  width: 15rem;
  height: 100vh;
  display: none;
  opacity: 0;
  width: fit-content;
  &.admin {
    color: rgb(43, 43, 43);
    font-size: 0.9rem;
    & .sidebar__header {
      @media screen and (max-width: 889px) {
        display: none;
      }
      background-color: #2b3141;
    }
    & .sidebar__items {
      & .sidebar__item {
        & li {
          font-size: 1.01rem;

          color: var(--color-faded-darker);
          & svg {
            font-size: 1.2rem;
          }
          & span {
            width: 18rem;
            @media screen and (max-width: 889px) {
              display: none;
            }
          }
        }
        &:hover {
          background-image: linear-gradient(90deg, #014eb3, #0097ce);
          & li {
            color: #fff;
          }
        }
        &.selected {
          background-image: linear-gradient(90deg, #0097ce, #014eb3);
          & li {
            color: #fff;
          }
        }

        // & svg {
        //   font-size: 1.2rem;
        // }
        // & a {
        //   font-size: 1.03rem;
        //   color: rgb(0, 0, 0);
        // }
        // &.selected {
        //   background-image: linear-gradient(90deg, #0097ce, #014eb3);
        //   color: #fff;
        //   & a {
        //     color: #fff;
        //   }
        // }
        // &:hover {
        //   background-image: linear-gradient(90deg, #0097ce, #014eb3);
        //   & a {
        //     color: #fff;
        //   }
        // }
      }
    }
  }
  &.user {
    background-color: #2b3141;
    color: #fff;
    & .sidebar__items {
      & .sidebar__item:hover {
        background-image: linear-gradient(90deg, #0097ce, #014eb3);
      }
      & .selected {
        background-image: linear-gradient(90deg, #0097ce, #014eb3);
      }
    }
  }
  &__header {
    padding: 0px 0px 0px 5px;
    min-width: 190px;
    & img {
      width: 100%;
      height: 58px;
    }
  }
  &__items {
    list-style: none;
    padding-top: 1rem;
  }
  &__item {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0.6rem 0.6rem 0.6rem 1.5rem;
    @media screen and (max-width: 889px) {
      padding: 1rem;
      width: 100%;
    }
    gap: 0.625rem;
  }
}
.opened {
  max-width: 15rem;
  animation: mount 0.4s;
  display: block;
  opacity: 1;
  & .sidebar__header {
    display: block;
  }
  & ~ .main-container {
    max-width: 85%;
  }
}
