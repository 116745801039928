.forgotpass-card {
    width: 80vw;
    height: 80vh;
    display: flex;
    justify-content: center;
}

.pass-correction-btn:hover {
    background-image: linear-gradient(109.6deg, #17a2b8 11.2%, rgb(19 40 242) 91.1%);
    border: none;
    width: 30%;
}

.pass-correction-btn {
    background-color: white;
    color: aqua;
    border-color: grey;
    width: 30%;
    font-weight: 600;
    min-width: 137px;
}

@media only screen and (min-width: 768px) {

    /* For larger devices: */
    .forgotpass-card-main {
        width: 40vw;
        height: 80vh
    }
}
@media only screen and (max-width: 768px) {

    /* For mobile phones: */
    .forgotpass-card-main {
        height: 50%;
    }
    .forgotpass-card {
        align-items: center;
    }
}
