.modal-content {
  padding: 0 5px 2.5rem 5px;
}
.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #0091cc;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0b5ed7;
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5pxrgba (0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0d6efd;
  --bs-btn-disabled-border-color: #0d6efd;
}
.btn-reset {
  background-color: #1068ac;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
  width: 40rem;
}

@media (min-width: 768px) {
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
    height: 50vh;
    margin: auto;
  }
}
.text-center {
  text-align: center !important;
  margin: auto;
}
