.trim {
  &__header {
    & > h5 {
      color: var(--color-faded-darker);
    }
  }
  &__dropdowns {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    & > button {
      background-image: linear-gradient(90deg, #0097ce, #014eb3);
    }
  }
}
