.main-container {
  width: 100%;
  &.admin {
    background-color: #f2f4f8;
  }
}
.content {
  margin: 1rem;
}
.page {
  width: 100%;
  background-color: #fff;
  padding: 0.625rem;
}
.ant-breadcrumb {
  & li:last-child {
    & a {
      color: #019ffc;
    }
  }
}
