.main-toggle {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  & .toggle {
    background-color: #0091cf;
    width: 1.7rem;
    height: 0.9rem;
    display: flex;
    align-items: center;
    border-radius: 10px;
    &__toggler {
      height: 0.8rem;
      width: 0.8rem;
      border-radius: 50%;
      background-color: #fff;
    }
    &.left {
      justify-content: flex-end;
    }
  }
  & p {
    color: var(--color-faded-dark);
  }
}
.fixed-segments {
  &__controls {
    @include headerSelect;

    & .type__dropdown {
      & .type__header {
        padding: 0.2rem;
        border: 1px solid var(--color-primary);
        border-radius: 3px;
        color: var(--color-primary);
      }
      & .type__items {
        list-style: none;
        position: absolute;
        width: fit-content;
        border: 1px solid var(--color-primary);
        background-color: #f8f8f8;
        @include itemCenter(column);
      }
      & .type__item {
        padding: 0.5rem 1rem;
        @include itemCenter(row);
        &:hover {
          background-color: #e0e3e8;
        }
      }
    }
  }
  & .details-controls {
    display: flex;
    justify-content: flex-end;
    padding: 5px 1rem;
    margin: 1.3rem 0;
    gap: 0.8rem;
    & > button {
      @include btnPlain;
      color: var(--color-primary);
      &:active {
        animation: onClickBtn 0.05s ease-out;
      }
      &:hover {
        background-color: rgb(242, 242, 242);
      }
    }
  }
  &__data-options {
    margin-top: 1rem;
    margin-right: 1rem;
    display: flex;
    justify-content: space-between;
    &__tools {
      @include itemCenter(row);
      gap: 1rem;
      & span {
        cursor: pointer;
        font-size: 1.3rem;
        color: var(--color-faded-dark);
      }
    }
  }
  &__single-row {
    display: flex;
    justify-content: space-between;
    border: 1px solid #b4b4b43d;
    padding-right: 1rem;
    color: var(--color-faded-dark);

    &__heading {
      text-transform: uppercase;
      font-size: 1rem;
      flex: 0 0 50%;
      background-color: #edf4fa;
      padding: 0.5rem 1rem;
      & h6 {
        font-size: 0.9rem;
        color: var(--color-faded-darker);
        margin-bottom: 0;
      }
    }
    &__item {
      margin: auto;
    }
  }
}
